export default {
  name: "ListTVirtualAccountPpob",
  data() {
    return {
      property: {
        modal: {
          showModalAddVirtualAccountPpob: false,
          showModalEditVirtualAccountPpob: false,
          label: "",
        },
        animation: {
          addVirtualAccountPpob: {
            isLoading: false,
          },
          editVirtualAccountPpob: {
            isLoading: false,
          },
        },
        filterDto: {
          dateFrom: "",
          dateTo: "",
          virtualAccountNumber: "",
          statusId: "",
        },
        listElement: {
          virtualAccountPpob: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
      },
      table: {
        data: {
          virtualAccountPpob: [],
        },
      },
      dataForm: {
        add: {
          description: "",
          nominal: "",
        },
        edit: {
          virtualAccountId: "",
          description: "",
          nominal: "",
        },
      },
      options: {
        statusAccount: [],
      },
    };
  },
  methods: {
    resetListVirtualAccountPpob() {
      this.property.filterDto.dateFrom = "";
      this.property.filterDto.dateTo = "";
      this.property.filterDto.virtualAccountNumber = "";
      this.property.listElement.virtualAccountPpob.rows = 0;
    },
    async getVirtualAccountPpob() {
      this.table.data.virtualAccountPpob = [];
      this.property.listElement.virtualAccountPpob.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account-ppob",
          payload: {
            dateFrom: "",
            dateTo: "",
            statusId: "",
            virtualAccountNumber: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccountPpob = resp.data.data.content;
            this.property.listElement.virtualAccountPpob.rows =
              resp.data.data.totalElements;
          } else {
            console.log(resp);
          }
        }, timeout);
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccountPpob.downloading = false;
        }, timeout);
      }
    },
    async addVirtualAccountPpob() {
      const payload = {
        description: this.dataForm.add.description,
        nominal: this.dataForm.add.nominal,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addVirtualAccountPpob.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "virtual-account-ppob",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.dialog.alert({
                    title: "Success !",
                    message: "Berhasil menyimpan data",
                    confirmText: "Ok",
                    type: "is-success",
                    onConfirm: () => {
                      this.resetListVirtualAccountPpob();
                      this.getVirtualAccountPpob();
                    },
                  });
                  this.closeModalAddVirtualAccountPpob();
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                  console.log(resp.data.message);
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addVirtualAccountPpob.isLoading = false;
              }, 500);
            }
          },
        });
      }
    },
    async editVirtualAccountPpob() {
      const payload = {
        description: this.dataForm.edit.description,
        nominal: this.dataForm.edit.nominal,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editVirtualAccountPpob.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl:
                  "virtual-account-ppob/" + this.dataForm.edit.virtualAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.dialog.alert({
                    title: "Success !",
                    message: "Berhasil menyimpan data",
                    confirmText: "Ok",
                    type: "is-success",
                    onConfirm: () => {
                      this.resetListVirtualAccountPpob();
                      this.getVirtualAccountPpob();
                    },
                  });
                  this.closeModalEditVirtualAccountPpob();
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                  console.log(resp.data.message);
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editVirtualAccountPpob.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async deleteVirtualAccountPpob(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Hapus Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl: "virtual-account-ppob/" + props.row.virtualAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.resetListVirtualAccountPpob();
                  this.getVirtualAccountPpob();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async searchByDateFromVirtualAccountPpob(event) {
      this.table.data.virtualAccountPpob = [];
      this.property.listElement.virtualAccountPpob.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account-ppob",
          payload: {
            dateFrom: event,
            dateTo: this.property.filterDto.dateTo,
            virtualAccountNumber: this.property.filterDto.virtualAccountNumber,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccountPpob = resp.data.data.content;
            this.property.listElement.virtualAccountPpob.rows =
              resp.data.data.totalElements;
            this.property.listElement.virtualAccountPpob.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetVirtualAccountPpob(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccountPpob(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccountPpob.downloading = false;
        }, timeout + 500);
      }
    },
    async searchByDateToVirtualAccountPpob(event) {
      this.table.data.virtualAccountPpob = [];
      this.property.listElement.virtualAccountPpob.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account-ppob",
          payload: {
            dateFrom: this.property.filterDto.dateForm,
            dateTo: event,
            virtualAccountNumber: this.property.filterDto.virtualAccountNumber,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccountPpob = resp.data.data.content;
            this.property.listElement.virtualAccountPpob.rows =
              resp.data.data.totalElements;
            this.property.listElement.virtualAccountPpob.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetVirtualAccountPpob(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccountPpob(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccountPpob.downloading = false;
        }, timeout + 500);
      }
    },
    async searchByVirtualAccountNumberVirtualAccountPpob(event) {
      this.table.data.virtualAccountPpob = [];
      this.property.listElement.virtualAccountPpob.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account-ppob",
          payload: {
            dateFrom: this.property.filterDto.dateForm,
            dateTo: this.property.filterDto.dateTo,
            virtualAccountNumber: event,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccountPpob = resp.data.data.content;
            this.property.listElement.virtualAccountPpob.rows =
              resp.data.data.totalElements;
            this.property.listElement.virtualAccountPpob.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetVirtualAccountPpob(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccountPpob(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccountPpob.downloading = false;
        }, timeout + 500);
      }
    },
    async changePaginationVirtualAccountPpob(event) {
      this.table.data.virtualAccountPpob = [];
      this.property.listElement.virtualAccountPpob.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account-ppob",
          payload: {
            dateFrom: this.property.filterDto.dateFrom,
            dateTo: this.property.filterDto.dateTo,
            virtualAccountNumber: this.property.filterDto.virtualAccountNumber,
            statusId: this.property.filterDto.statusId,
            page: event - 1,
            size: this.property.listElement.virtualAccountPpob.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccountPpob = resp.data.data.content;
            this.property.listElement.virtualAccountPpob.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetVirtualAccountPpob(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccountPpob(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccountPpob.downloading = false;
        }, timeout);
      }
    },
    failedGetVirtualAccountPpob(resp) {
      this.table.data.virtualAccountPpob = [];
      this.property.listElement.virtualAccountPpob.rows = 0;
      this.property.listElement.virtualAccountPpob.message = resp.data.message;
    },
    handleErrorVirtualAccountPpob(error) {
      console.log(error.response);
      this.table.data.virtualAccountPpob = [];
      this.property.listElement.virtualAccountPpob.rows = 0;
      this.property.listElement.virtualAccountPpob.currentPage = 1;

      this.property.listElement.virtualAccountPpob.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    showModalAddVirtualAccountPpob() {
      this.property.modal.showModalAddVirtualAccountPpob = true;
      this.property.modal.label = "FORM ADD VIRTUAL ACCOUNT PPOB";
    },
    closeModalAddVirtualAccountPpob() {
      this.property.animation.addVirtualAccountPpob.isLoading = false;
      this.property.modal.showModalAddVirtualAccountPpob = false;
      this.dataForm.add.description = "";
      this.dataForm.add.nominal = "";
    },
    async showModalEditVirtualAccountPpob(props) {
      this.property.modal.showModalEditVirtualAccountPpob = true;
      this.property.modal.label = "FORM EDIT VIRTUAL ACCOUNT PPOB";
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl: "virtual-account-ppob/" + props.row.virtualAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.virtualAccountId =
              resp.data.data.virtualAccountId;
            this.dataForm.edit.description = resp.data.data.description;
            this.dataForm.edit.nominal = resp.data.data.nominal;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    closeModalEditVirtualAccountPpob() {
      this.property.animation.editVirtualAccountPpob.isLoading = false;
      this.property.modal.showModalEditVirtualAccountPpob = false;
      this.dataForm.edit.virtualAccountId = "";
      this.dataForm.edit.description = "";
      this.dataForm.edit.nominal = "";
    },
    routeToPagePaymentVirtualAccountPpob(props) {
      sessionStorage.setItem(
        "VIRTUAL_ACCOUNT_NUMBER_FOR_PPOB_PAYMENT",
        props.row.virtualAccountId
      );
      this.$router.push("payment");
    },
    routeToPageDetailVirtualAccountPpob(props) {
      sessionStorage.setItem(
        "VIRTUAL_ACCOUNT_NUMBER_DETAIL_PPOB",
        props.row.virtualAccountId
      );
      this.$router.push("detail");
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.getVirtualAccountPpob();
    this.getReferenceStatusAccount();
  },
};
